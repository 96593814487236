import { defineStore } from 'pinia'
import type { InsuranceAgent } from '~/types';
import nuxtStorage from 'nuxt-storage';

export const useAgentInfoStore = defineStore('agent-info', {

  state: () => ({
    insuranceAgent: {} as InsuranceAgent,
    isSplitAgent: false
  }),
  persist: true,
  actions: {
    setInsuranceAgent (value: InsuranceAgent) {
      this.insuranceAgent = value
    },
    setIsSplitAgent (value: boolean) {
      this.isSplitAgent = value
    },
    reset() {
      nuxtStorage?.sessionStorage?.removeItem('agent-info');
    },
  }
})
